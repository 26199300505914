import { useState } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";

import CTAButton from "../../components/CTAButton";
import MainHeaderContainer from "../../components/MainHeaderContainer";

// Images
import flexioTextLogo from "../../assets/images/webp/flexio_text_logo.webp";
import flexioWhiteLogoImg from "../../assets/images/webp/logo-white.webp";
import squaresImg from "../../assets/images/webp/squares.webp";
import flexioSquareImg from "../../assets/images/webp/flexio_square.webp";
import howItsDone1Img from "../../assets/images/webp/how-its-done-1.webp";
import howItsDone2Img from "../../assets/images/webp/how-its-done-2.webp";
import howItsDone3Img from "../../assets/images/webp/how-its-done-3.webp";
import ecommerceImg from "../../assets/images/webp/ecommerce-sq.webp";
import automotiveImg from "../../assets/images/webp/Automotive-sq.webp";
import groceryImg from "../../assets/images/webp/grocery-sq.webp";
import pharmacyImg from "../../assets/images/webp/pharmacy-sq.webp";
import shopImg from "../../assets/images/webp/shop-sq.webp";
import retailImg from "../../assets/images/webp/retail-sq.webp";
import backpatternImg from "../../assets/images/webp/backpattern-homesquare.webp";
import techServiceImg from "../../assets/images/webp/tech-service.webp";
import deliveryServiceImg from "../../assets/images/webp/delivery-service.webp";
import customServiceImg from "../../assets/images/webp/custom-service.webp";
import hybridSolutionImg from "../../assets/images/webp/hybrid-solution.webp";
import fresHouseImg from "../../assets/images/webp/fresh-house.webp";
import novaImg from "../../assets/images/webp/nova.webp";
import caridadImg from "../../assets/images/webp/caridad.webp";
import fusionImg from "../../assets/images/webp/fusion.webp";
import strainExpressImg from "../../assets/images/webp/alivia-health.webp";
import strainImg from "../../assets/images/webp/centraldrug.webp";
import crioliteImg from "../../assets/images/webp/criolite.webp"
import workerImg from "../../assets/images/webp/worker.webp";
import homeHeaderImg from "../../assets/images/webp/home-header.webp";

const Home = () => {
  const [tabEventKey, setTabEventKey] = useState({
    customService: false,
    flexioTech: true,
    hybridSolution: false,
    deliveryService: false
  });
  return (
    <>
      <MainHeaderContainer
        Title={() => (
          <span>
            Your Logistics Partner
          </span>
        )}
        mainText="Optimize your deliveries with Flexio, where advanced logistics tech seamlessly integrates with a vast network of external drivers, enhancing efficiency and expanding capabilities."
        headerImg={homeHeaderImg}
        showLogo
        mainTextClass="pr-2"
        logoImgSize="250"
      >
        <CTAButton
          to="/"
          // onClick={() => window.open("http://myaccount.flexiopr.com/")}
          onClick={() => window.open("https://app.goflexio.com/register")}
          /*onClick={(e) => {
            e.preventDefault();
            window.FreshworksWidget("open");
          }}*/
        >
          GET STARTED
        </CTAButton>
      </MainHeaderContainer>
      {/* Why Flexio */}
      <Image src={squaresImg} fluid width={350} height="auto" alt="Flexio decoration" />
      <WhyContainer className="mb-10">
        <Row>
          <Col md="5" className="d-flex justify-content-center">
            <Image src={workerImg} fluid width={500} height="auto" alt="Why Flexio" />
          </Col>
          <Col md="7">
            <div className="">
              <div className="text-justify">
                <div>
                  <h2 className="mb-4 text-center">Welcome to FLEXIO</h2>
                  <div className="mt-3 mb-4">
                    <WhyDescription>
                    Elevating delivery services to new heights with our innovative logistics solutions! Leveraging cutting-edge technology and a robust crowdsourced network, we offer unparalleled efficiency and reliability for both medium and last-mile deliveries, serving businesses and individuals alike. Experience the future of delivery with FLEXIO's unique hybrid approach today!
                    </WhyDescription>
                  </div>
                </div>
              </div>
              <Container>
                <Row>
                <Col md="6" sm="12">
                    <ListTitle>Routing & Fleet Management Tech</ListTitle>
                    <WhyList>
                      <li>
                        <ListRectangle />
                        <div>
                        Elevate fleet management with unparalleled efficiency using our industry-leading technology.
                        </div>
                      </li>
                      <li>
                        <ListRectangle />
                        <div>
                        Customize your workflow seamlessly to ensure full compliance with ease and efficiency.
                        </div>
                      </li>
                      <li>
                        <ListRectangle />
                        <div>
                        Empower your team and enhance decision-making with our unparalleled data analytics, tailored for optimal performance.
                        </div>
                      </li>
                    </WhyList>
                    <LearMoreLink to="/software">Learn more</LearMoreLink>
                  </Col>
                  <Col md="6" sm="12">
                    <ListTitle>Delivery Services</ListTitle>
                    <WhyList>
                      <li>
                        <ListRectangle />
                        <div>
                        Scale effortlessly without the overhead of a fleet or team, propelling your growth seamlessly.
                        </div>
                      </li>
                      <li>
                        <ListRectangle />
                        <div>
                        Discover our diverse fleet, ranging from spacious vans to compact cars, tailored to meet all your transportation needs.
                        </div>
                      </li>
                      <li>
                        <ListRectangle />
                        <div>
                        Enjoy flexible usage with no minimum contract—only pay for what you need, when you need it.
                        </div>
                      </li>
                    </WhyList>
                    <LearMoreLink to="/delivery">Learn more</LearMoreLink>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <FlexioSqaureImage src={flexioSquareImg} fluid width={150} height="auto" alt="Why Flexio" />
        </div>
      </WhyContainer>
      {/* How its done */}
      <HowItsDoneContainer fluid className="mb-10">
        <div className="text-center">
          <small className="mb-3 d-block">
            Flexio offers the smartest solution for Delivery and Fleet
            Management
          </small>
          <h2>The Flexio Way: How it's done</h2>
        </div>
        <FlexImageContainer>
          <div className="flex-container-item">
            <Image src={howItsDone1Img} width="auto" height="auto" fluid alt="Step 1" />
            <ColorBar primary />
            <StepsContainer>
              <div>
                <p className="steps-title">Step 1</p>
                <p className="steps-description">
                  We evaluate your exact needs <br /> and offer the best
                  solutions <br /> that meet your goals.
                </p>
              </div>
            </StepsContainer>
          </div>
          <div className="flex-container-item">
            <Image src={howItsDone2Img} width="auto" height="auto" fluid alt="Step 2" />
            <ColorBar secondary />
            <StepsContainer>
              <div>
                <p className="steps-title">Step 2</p>
                <p className="steps-description">
                  Whether it's Drivers,
                  <br /> Technology or a combination
                  <br /> of both...
                </p>
              </div>
            </StepsContainer>
          </div>
          <div className="flex-container-item">
            <Image src={howItsDone3Img} width="auto" height="auto" fluid alt="Step 3" />
            <ColorBar />
            <StepsContainer>
              <div className="steps-wrapper">
                <p className="steps-title">Step 3</p>
                <p className="steps-description">
                  We make sure your merch
                  <br /> gets where it needs to be.
                  <br /> Safely and on time.
                </p>
              </div>
            </StepsContainer>
          </div>
        </FlexImageContainer>
      </HowItsDoneContainer>
      {/* Empowering */}
      <EmpoweringContainer className="mb-10">
        <h2 className="text-center">Empowering Industries</h2>
        <FlexSquares>
          <div>
            <Image src={retailImg} width="auto" height="auto" fluid alt="Manufacturing" />
            <p className="mt-3 text-center">Manufacturing</p>
          </div>
          <div>
            <Image src={automotiveImg} width="auto" height="auto" fluid alt="Distribution" />
            <p className="mt-3 text-center">Distribution</p>
          </div>
          <div>
            <Image src={ecommerceImg} width="auto" height="auto" fluid alt="Retail" />
            <p className="mt-3 text-center">Retail</p>
          </div>
          <div>
            <Image src={pharmacyImg} width="auto" height="auto" fluid alt="Pharmacy" />
            <p className="mt-3 text-center">Pharmacy</p>
          </div>
          <div>
            <Image src={shopImg} width="auto" height="auto" fluid alt="E-commerce" />
            <p className="mt-3 text-center">E-commerce</p>
          </div>
          <div>
            <Image src={groceryImg} width="auto" height="auto" fluid alt="Individuals" />
            <p className="mt-3 text-center">Individuals</p>
          </div>
        </FlexSquares>
      </EmpoweringContainer>

      {/* Custom Service */}
      <CustomServiceContainer className="mb-10" fluid>
        <Row>
          <Col
            md="6"
            sm="12"
            style={{
              position: "relative"
            }}
          >
            <Image src={backpatternImg} fluid width="600" height="auto" alt="Flexio Square Pattern" />
            <AbsoluteImgContainer>
              <div>
                <div
                  style={{
                    border: `solid 4px ${
                      tabEventKey.flexioTech ? "#17EAD9" : "transparent"
                    }`,
                    transition: "all 0.5s ease",
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    setTabEventKey({
                      customService: false,
                      flexioTech: true,
                      hybridSolution: false,
                      deliveryService: false
                    })
                  }
                >
                  <OverlayContainer>
                    <AbsoluteTitle>Flexio Tech</AbsoluteTitle>
                    <Image
                      src={techServiceImg}
                      width="300"
                      height="auto"
                      fluid
                      className="d-block"
                      alt="Flexio Tech"
                    />
                  </OverlayContainer>
                </div>
                <div
                  style={{
                    border: `solid 4px ${
                      tabEventKey.hybridSolution ? "#17EAD9" : "transparent"
                    }`,
                    transition: "all 0.5s ease",
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    setTabEventKey({
                      customService: false,
                      flexioTech: false,
                      hybridSolution: true,
                      deliveryService: false
                    })
                  }
                >
                  <OverlayContainer>
                    <AbsoluteTitle>Hybrid Solution</AbsoluteTitle>
                    <Image
                      src={hybridSolutionImg}
                      width="300"
                      height="auto"
                      fluid
                      className="d-block"
                      alt="Hybrid Solution"
                    />
                  </OverlayContainer>
                </div>
                {/* <div
                  style={{
                    width: "250px",
                    height: "250px",
                    background: "#000",
                    margin: "10px",
                    border: `solid 3px ${
                      tabEventKey.hybridSolution ? "#17EAD9" : "transparent"
                    }`
                  }}
                  onClick={() =>
                    setTabEventKey({
                      customService: false,
                      flexioTech: false,
                      hybridSolution: true,
                      deliveryService: false
                    })
                  }
                ></div> */}
              </div>
              <div>
                <div
                  style={{
                    border: `solid 4px ${
                      tabEventKey.deliveryService ? "#17EAD9" : "transparent"
                    }`,
                    transition: "all 0.5s ease",
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    setTabEventKey({
                      customService: false,
                      flexioTech: false,
                      hybridSolution: false,
                      deliveryService: true
                    })
                  }
                >
                  <OverlayContainer>
                    <AbsoluteTitle>Delivery Service</AbsoluteTitle>
                    <Image
                      src={deliveryServiceImg}
                      width="300"
                      height="auto"
                      className="d-block"
                      fluid
                      alt="Delivery Service"
                    />
                  </OverlayContainer>
                </div>
                <div
                  style={{
                    border: `solid 4px ${
                      tabEventKey.customService ? "#17EAD9" : "transparent"
                    }`,
                    transition: "all 0.5s ease",
                    cursor: "pointer"
                  }}
                  onClick={() =>
                    setTabEventKey({
                      customService: true,
                      flexioTech: false,
                      hybridSolution: false,
                      deliveryService: false
                    })
                  }
                >
                  <OverlayContainer>
                    <AbsoluteTitle>Custom Service</AbsoluteTitle>
                    <Image
                      src={customServiceImg}
                      width="300"
                      height="auto"
                      className="d-block"
                      fluid
                      alt="Custom Service"
                    />
                  </OverlayContainer>
                </div>
              </div>
            </AbsoluteImgContainer>
          </Col>
          <Col md="6" sm="12">
            {tabEventKey.customService && (
              <IndustryTab>
                <div style={{ width: "80%" }}>
                  {/* <p style={{ color: "#FF2F2F", fontWeight: "500" }}>
                    Lorem Ipsum
                  </p> */}
                  <h2 className="mb-5">Custom Service</h2>

                  <p>
                  Recognizing the unique nature of your operations, Flexio offers tailor-made services and structures to meet your specific needs. Reach out to us, and we'll dedicate our best minds to understanding your requirements, ensuring a personalized solution crafted just for you.
                  </p>
                </div>
              </IndustryTab>
            )}
            {tabEventKey.flexioTech && (
              <IndustryTab>
                <div style={{ width: "80%" }}>
                  {/* <p style={{ color: "#FF2F2F", fontWeight: "500" }}>
                    Lorem Ipsum
                  </p> */}
                  <h2 className="mb-5">Flexio Fleet Management and Routing Tech</h2>

                  <p>
                  Many businesses lack the necessary or appropriate technology to effectively manage and expand their delivery operations.
                  </p>
                  <p>
                  Flexio offers the market’s most comprehensive solution for fleet management and routing, ensuring optimal efficiency.
                  </p>
                  <p>
                  Leverage our advanced technology to effortlessly optimize your fleet and accelerate your growth trajectory in record time.
                  </p>
                </div>
              </IndustryTab>
            )}
            {tabEventKey.hybridSolution && (
              <IndustryTab>
                <div style={{ width: "80%" }}>
                  {/* <p style={{ color: "#FF2F2F", fontWeight: "500" }}>
                    Lorem Ipsum
                  </p> */}
                  <h2 className="mb-5">Hybrid Solution</h2>

                  <p>
                  Companies with internal fleets often grapple with optimizing management and struggle during peak demands, usually resorting to inconsistent, independently-priced drivers for backup.
                  </p>
                  <p>
                  Flexio's holistic approach addresses this. Our Hybrid Solution lets you channel overflow to Flexio while managing your in-house drivers on the same platform. Plus, during downtimes, deploy your drivers to Flexio assignments and boost your revenue.
                  </p>
                </div>
              </IndustryTab>
            )}
            {tabEventKey.deliveryService && (
              <IndustryTab>
                <div style={{ width: "80%" }}>
                  {/* <p style={{ color: "#FF2F2F", fontWeight: "500" }}>
                    Lorem Ipsum
                  </p> */}
                  <h2 className="mb-5">Delivery Service</h2>

                  <p>
                  In today's delivery-driven economy, quick and efficient service is expected for virtually all goods, challenging businesses of every size to keep up.
                  </p>
                  <p>
                  Flexio offers versatile delivery services tailored to diverse needs, whether you handle low or high volumes of goods.
                  </p>
                  <p>
                  Partner with Flexio to guarantee your customers enjoy the pinnacle of delivery experiences.
                  </p>
                </div>
              </IndustryTab>
            )}
          </Col>
        </Row>
      </CustomServiceContainer>
      <Container className="mb-10">
        <h2 className="text-center" style={{ marginBottom: "3em" }}>
          Trusted by Brands
        </h2>
        <BrandsContainer>
          <Image src={strainImg} width="auto" height="auto" fluid alt="Farmacia Central Drug" />
          <Image src={fusionImg} width="auto" height="auto" fluid alt="Fusion Consulting Group" />
          <Image src={novaImg} width="auto" height="auto" fluid alt="Nova Group Corp" />
          <Image src={strainExpressImg} width="auto" height="auto" fluid alt="Alivia Specialty Pharmacy" />
          <Image src={crioliteImg} width="auto" height="auto" fluid alt="Criolite" />
          <Image src={caridadImg} width="auto" height="auto" fluid alt="Farmacias Caridad" />
          <Image src={fresHouseImg} width="auto" height="auto" fluid alt="Fresh House" />
        </BrandsContainer>
      </Container>
    </>
  );
};

const FlexCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
`;

const HomeRow = styled(Row)`
  min-height: 500px;
  margin-bottom: 2em;

  @media (max-width: 720px) {
    flex-flow: column-reverse wrap;
  }
`;

const TextContainer = styled.div`
  margin-top: 0.8em;
  margin-left: 0.8em;
`;

const ListTitle = styled.h4`
  text-align: center;
  font-size: 1em;
  margin-bottom: 1em;
`;

const WhyContainer = styled.div`
  padding: 0 4em;

  @media (max-width: 720px) {
    padding: 1em;
  }
`;

const WhyDescription = styled.p`
  max-width: 800px;
  margin: 3em auto;
  font-size: 1.2em;
  text-align: justify;
`;

const WhyList = styled.ul`
  padding: 0;
  font-size: 1.1em;

  li {
    margin: 1em 0;
    height: 4em;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
  }

  @media (max-width: 1200px) {
    li {
      height: auto;
    }
  }
`;

const LearMoreLink = styled(Link)`
  color: #6078ea;
  text-align: right;
  font-weight: bold;
  border-bottom: solid 2px #6078ea;
  float: right;
  text-decoration: none !important;
`;

const ListRectangle = styled.div`
  height: 11px;
  width: 11px;
  border: 3px solid #17ead9;
  margin: 10px;
`;

const FlexioSqaureImage = styled(Image)`
  display: block;
  // float: right;
`;

const HowItsDoneContainer = styled(Container)``;

const EmpoweringContainer = styled(Container)`
  max-width: 1024px;
`;

const FlexImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2em 2em 0 2em;

  & img {
    display: block;
    border: 0;
  }

  @media (max-width: 940px) {
    & img {
      width: 100%;
    }
  }

  @media (max-width: 720px) {
    margin: 1em;
    flex-flow: wrap;
  }
`;

const ColorBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: ${(props) =>
    props.primary ? "#3c54a5" : props.secondary ? "#6078EA" : "#17EAD9"};
  margin: 0;
  border: solid 1px
    ${(props) =>
      props.primary ? "#3c54a5" : props.secondary ? "#6078EA" : "#17EAD9"};
`;

const StepsContainer = styled.div`
  padding: 1em;
  background-color: #fff2ff;
  margin: 0;

  & p {
    color: #3c54a5;
    font-size: 1.6em;
  }

  & .steps-title {
    margin: 0;
  }

  & .steps-description {
    font-family: "Tajawal Bold";
  }
`;

const FlexSquares = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4em;
  flex-flow: row wrap;

  & img {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 720px) {
    justify-content: center;
  }
`;

const CustomServiceContainer = styled(Container)`
  margin-top: 15em;
`;

const AbsoluteImgContainer = styled.div`
  position: absolute;
  width: 100%;
  top: -150px;
  right: -150px;
  display: flex;
  flex-flow: wrap;

  @media (max-width: 1200px) {
    position: initial;
    justify-content: center;
  }
`;

const AbsoluteTitle = styled.h3`
  position: absolute;
  z-index: 999;
  color: #fff;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const BrandsContainer = styled.div`
  // display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;
  text-align: center;

  & img {
    display: inline-block;
    margin: 0 1em 2em 1em;
    max-width: 130px;
  }
`;

const ImageLogo = styled(Image)`
  @media (max-width: 720px) {
    display: none;
  }
`;

const ImageWhiteLogo = styled(Image)`
  display: none;

  @media (max-width: 720px) {
    display: block;
  }
`;

const OverlayContainer = styled.div`
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000050;
    top: 0;
    left: 0;
  }
`;

const IndustryTab = styled.div`
  margin-left: 3em;
  padding: 1.5em;
  display: flex;
  justify-content: start;

  & p {
    font-size: 1.3em;
  }

  @media (max-width: 1024px) {
    margin: 0;
    justify-content: center;
    padding: 1.5em 0;
  }
`;

export default Home;
