import { useEffect, useState, React, lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NavigationBar from "./components/NavigationBar";
import { ThemeProvider } from "styled-components";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

// Components
import Home from "./pages/Home";
//import Delivery from "./pages/Delivery";
//import Solutions from "./pages/Solutions";
//import ContactUs from "./pages/Contact Us";
//import Software from "./pages/Software";
import MobileMenu from "./components/MobileMenu";
//import BecomeADriver from "./pages/Become Driver";
//import Industries from "./pages/Industries";
import AboutUs from "./pages/About Us";
//import ClientTerms from "./pages/Client Terms";
//import PrivacyPolicy from "./pages/Privacy Policy";
//import DriverTerms from "./pages/Driver Terms";
//import NextSteps from "./pages/Next Steps";
//import ActiveNextSteps from "./pages/Active Next Steps";
//import Training from "./pages/Training";
const Delivery = lazy( () => import("./pages/Delivery"));
const BecomeADriver = lazy(() => import("./pages/Become Driver"));
const Solutions = lazy( () => import("./pages/Solutions"));
const ContactUs = lazy(() => import ("./pages/Contact Us"));
const Software = lazy( () => import("./pages/Software"));
const Industries = lazy( () => import("./pages/Industries"));
const ClientTerms = lazy(() => import("./pages/Client Terms"));
const PrivacyPolicy = lazy( ()=> import("./pages/Privacy Policy"));
const DriverTerms = lazy(() => import("./pages/Driver Terms"));
const NextSteps = lazy( () => import("./pages/Next Steps"));
const Training =lazy(() => import("./pages/Training"))
const ActiveNextSteps = lazy( () => import("./pages/Active Next Steps"));
const Footer = lazy(() => import("./components/Footer"));

function App() {
  const location = useLocation();
  const [showArrowUp, setShowArrowUp] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    // Create a fake percentage
    // console.log(document.readyState);

    document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setShowArrowUp(true);
      }

      if (window.scrollY < 1) {
        setShowArrowUp(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        {/* <Loader /> */}
        <MobileMenu />
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/delivery" element={<Suspense fallback={<div>Loading...</div>}><Delivery /></Suspense>} />
          <Route path="/solutions" element={<Suspense fallback={<div>Loading...</div>}><Solutions /></Suspense>} />
          {/* <Route path="/become-driver" element={<BecomeADriver />} /> */}
          <Route path="/become-driver" element={<Suspense fallback={<div>Loading...</div>}><BecomeADriver /></Suspense>} />
          <Route path="/software" element={<Suspense fallback={<div>Loading...</div>}><Software /></Suspense>} />
          <Route path="/industries" element={<Suspense fallback={<div>Loading...</div>}><Industries /></Suspense>} />
          <Route path="/contact-us" element={<Suspense fallback={<div>Loading...</div>}><ContactUs /></Suspense>} />
          {/* <Route path="/about-us" element={<AboutUs />} /> */}
          <Route path="/clientterms" element={<Suspense fallback={<div>Loading...</div>}><ClientTerms /></Suspense>} />
          <Route path="/privacy" element={<Suspense fallback={<div>Loading...</div>}><PrivacyPolicy /></Suspense>} />
          <Route path="/driverterms_eng" element={<Suspense fallback={<div>Loading...</div>}><DriverTerms /></Suspense>} />
          <Route path="/siguientespasos" element={<Suspense fallback={<div>Loading...</div>}><NextSteps /></Suspense>} />
          <Route path="/adiestramientosocios" element={<Suspense fallback={<div>Loading...</div>}><Training /></Suspense>} />
          <Route
            path="/activadosiguientespasos"
            element={<Suspense fallback={<div>Loading...</div>}><ActiveNextSteps /></Suspense>}
          />
          <Route
            path="*"
            element={
              <div className="m-5 text-center">
                <h1>404 Error</h1>
                <p style={{ fontSize: "1.5em" }}>Cannot find this page</p>
              </div>
            }
          />
        </Routes>
        <Suspense fallback={<div>Loading...</div>}>
        <Footer />
        </Suspense>
        {showArrowUp && (
          <GoUpContainer onClick={() => scrollUp()}>
            <FontAwesomeIcon icon={faArrowUp} color="#fff" size="2x" />
          </GoUpContainer>
        )}
      </AppContainer>
    </ThemeProvider>
  );
}

const AppContainer = styled.div`
  position: relative;
`;

const GoUpContainer = styled.div`
  background-color: rgb(96, 120, 234);
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 999;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const Loader = styled.div`
  width: 0;
  height: 8px;
  background: #6078ea;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1s ease-in-out;
  animation: loader 5s;

  @keyframes loader {
    0% {
      width: 0;
    }

    100% {
      width: 100%;
      display: none;
    }
  }
`;

const theme = {};

export default App;
