import { useState, useCallback } from "react";
import { useResolvedPath, useMatch } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const MobileMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div
      className="position-fixed"
      style={{
        backgroundColor: "#fff",
        width: "100%",
        padding: "0.5em",
        margin: 0,
        top: 0,
        left: 0,
        zIndex: "999"
      }}
    >
      <StyledHamburgerMenu onClick={() => setOpenMenu(!openMenu)}>
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
          <div className="hamburger-line"></div>
        </div>
      </StyledHamburgerMenu>
      <StyledMenu show={openMenu}>
        <CloseButton onClick={() => setOpenMenu(!openMenu)}>
          <FontAwesomeIcon icon={faClose} />
        </CloseButton>
        <LinksMenu>
          <div>
            <CustomLink to="/" onClick={() => setOpenMenu(false)}>
              HOME
            </CustomLink>
          </div>
          <div>
            <CustomLink to="delivery" onClick={() => setOpenMenu(false)}>
              DELIVERY
            </CustomLink>
          </div>
          <div>
            <CustomLink to="software" onClick={() => setOpenMenu(false)}>
              SOFTWARE
            </CustomLink>
          </div>
          <div>
            <CustomLink to="solutions" onClick={() => setOpenMenu(false)}>
              SOLUTIONS
            </CustomLink>
          </div>
          <div>
            <CustomLink to="industries" onClick={() => setOpenMenu(false)}>
              INDUSTRIES
            </CustomLink>
          </div>
          <div>
            <CustomLink to="become-driver" onClick={() => setOpenMenu(false)}>
              BECOME A DRIVER
            </CustomLink>
          </div>
          <AuthContainer>
            <div>
              <CustomLink to=""
                onClick={(e) => {
                   e.preventDefault();
                   window.open("https://calendly.com/adrian-medina/45min");
                 }}>
                REQUEST A DEMO
              </CustomLink>
              <CustomLink to="login" onClick={(e) => {
                   e.preventDefault();
                   window.open("https://app.goflexio.com/");
                 }}>
                LOGIN
              </CustomLink>
            </div>
            <div>
              <CustomLink to="register" onClick={(e) => {
                   e.preventDefault();
                   window.open("https://app.goflexio.com/register");
                 }}>
                REGISTER
              </CustomLink>
            </div>
          </AuthContainer>
        </LinksMenu>
      </StyledMenu>
    </div>
  );
};

const StyledHamburgerMenu = styled.div`
  width: 40px;
  height: 40px;
  display: none;
  position: relative;
  z-index: 999;
  cursor: pointer;
  //   transition: all 1s ease;
  margin: 0;

  & .hamburger-line {
    position: relative;
    width: 100%;
    height: 5px;
    background-image: linear-gradient(90deg, #17ead9, #6078ea);
    margin: 8px;
  }

  @media (max-width: 1024px) {
    display: block;
  }
`;

const StyledMenu = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-image: linear-gradient(#17ead9, #6078ea);
  z-index: 9999;
  transition: all 0.5s ease;
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
`;

const CloseButton = styled(Button)`
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 3em;
  background-color: transparent;
  border: 0;
  margin: 0.3em;

  &:hover {
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  &:focus-visible {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    outline: 0;
  }
`;

const LinksMenu = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  & a {
    display: block;
    color: #fff;
    font-size: 1.5em;
    margin: 0.4em;
    text-align: center;
  }
`;

const AuthContainer = styled.div`
  margin-top: 10em;
`;

const CustomLink = ({ to, children, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link to={to} {...props} style={{ fontWeight: match ? "bold" : "normal" }}>
      {children}
    </Link>
  );
};

export default MobileMenu;
