import { Container, Row, Col, Image } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import MainHeaderContainer from "../../components/MainHeaderContainer";

const AboutUs = () => {
  return (
    <>
      <MainHeaderContainer
        className="mtb-8"
        Title={() => "Lorem Ipsum"}
        mainText="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              ac ultricies turpis, id rhoncus lorem. Sed semper velit ac
              tristique lacinia. Mauris pretium a neque quis vestibulum."
      />
      <Container className="mtb-8">
        <Row>
          <Col
            md="6"
            style={{
              backgroundColor: "#dfdfdf"
            }}
          ></Col>
          <Col md="6" className="p-4">
            <h1 className="mb-4">Started from the bottom. Algo de historia</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              ac ultricies turpis, id rhoncus lorem.
            </p>
            <ul>
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li>
                Phasellus ac ultricies turpis, id rhoncus lorem. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit.
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus ac ultricies turpis, id rhoncus lorem.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container className="mtb-8 text-center">
        <h1 className="mb-4">The Flexio Team</h1>
        <div>
          <FlexContainer className="mb-4">
            <div>
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#dfdfdf",
                  borderRadius: "50%"
                }}
              ></div>
              <p className="fw-bold mt-4" style={{ color: "#BA2084" }}>
                Nombre de Persona
              </p>
              <p style={{ color: "#BA2084" }}>Puesto de Persona</p>
              <a
                href="https://linkedin.com"
                target="_blank"
                className="d-block"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" color="#6078EA" />
              </a>
            </div>
            <div>
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#dfdfdf",
                  borderRadius: "50%"
                }}
              ></div>
              <p className="fw-bold mt-4" style={{ color: "#BA2084" }}>
                Nombre de Persona
              </p>
              <p style={{ color: "#BA2084" }}>Puesto de Persona</p>
              <a
                href="https://linkedin.com"
                target="_blank"
                className="d-block"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" color="#6078EA" />
              </a>
            </div>
            <div>
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#dfdfdf",
                  borderRadius: "50%"
                }}
              ></div>
              <p className="fw-bold mt-4" style={{ color: "#BA2084" }}>
                Nombre de Persona
              </p>
              <p style={{ color: "#BA2084" }}>Puesto de Persona</p>
              <a
                href="https://linkedin.com"
                target="_blank"
                className="d-block"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" color="#6078EA" />
              </a>
            </div>
            <div>
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#dfdfdf",
                  borderRadius: "50%"
                }}
              ></div>
              <p className="fw-bold mt-4" style={{ color: "#BA2084" }}>
                Nombre de Persona
              </p>
              <p style={{ color: "#BA2084" }}>Puesto de Persona</p>
              <a
                href="https://linkedin.com"
                target="_blank"
                className="d-block"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" color="#6078EA" />
              </a>
            </div>
          </FlexContainer>
          <FlexContainer className="mt-4">
            <div>
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#dfdfdf",
                  borderRadius: "50%"
                }}
              ></div>
              <p className="fw-bold mt-4" style={{ color: "#BA2084" }}>
                Nombre de Persona
              </p>
              <p style={{ color: "#BA2084" }}>Puesto de Persona</p>
              <a
                href="https://linkedin.com"
                target="_blank"
                className="d-block"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" color="#6078EA" />
              </a>
            </div>
            <div>
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#dfdfdf",
                  borderRadius: "50%"
                }}
              ></div>
              <p className="fw-bold mt-4" style={{ color: "#BA2084" }}>
                Nombre de Persona
              </p>
              <p style={{ color: "#BA2084" }}>Puesto de Persona</p>
              <a
                href="https://linkedin.com"
                target="_blank"
                className="d-block"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" color="#6078EA" />
              </a>
            </div>
            <div>
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#dfdfdf",
                  borderRadius: "50%"
                }}
              ></div>
              <p className="fw-bold mt-4" style={{ color: "#BA2084" }}>
                Nombre de Persona
              </p>
              <p style={{ color: "#BA2084" }}>Puesto de Persona</p>
              <a
                href="https://linkedin.com"
                target="_blank"
                className="d-block"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" color="#6078EA" />
              </a>
            </div>
            <div>
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#dfdfdf",
                  borderRadius: "50%"
                }}
              ></div>
              <p className="fw-bold mt-4" style={{ color: "#BA2084" }}>
                Nombre de Persona
              </p>
              <p style={{ color: "#BA2084" }}>Puesto de Persona</p>
              <a
                href="https://linkedin.com"
                target="_blank"
                className="d-block"
              >
                <FontAwesomeIcon icon={faLinkedin} size="3x" color="#6078EA" />
              </a>
            </div>
          </FlexContainer>
        </div>
      </Container>
      <Container className="mtb-8">
        {/* <h2 className="text-center m-4">Our Partnered Brands</h2> */}
        <h2 className="text-center m-4">MISSION, VISION {"&"} PURPOSE</h2>
        <FlexContainer className="text-center">
          <BrandSquare>
            <div>
              <div
                style={{
                  background: "#dfdfdf",
                  width: "200px",
                  height: "200px",
                  margin: "auto"
                }}
              ></div>
              <p className="mt-4">Industry Type</p>
            </div>
          </BrandSquare>
          <BrandSquare>
            <div>
              <div
                style={{
                  background: "#dfdfdf",
                  width: "200px",
                  height: "200px",
                  margin: "auto"
                }}
              ></div>
              <p className="mt-4">Industry Type</p>
            </div>
          </BrandSquare>
        </FlexContainer>
      </Container>
    </>
  );
};

const StyledRow = styled(Row)`
  min-height: 500px;
`;

const StyledCol = styled(Col)`
  padding: 0 6em;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row wrap;

  & .flex-container-item {
    margin: 1em 5em;
  }
`;

const BrandSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #6078ea;
  width: 300px;
  height: 300px;
  margin-bottom: 2em;
`;

export default AboutUs;
