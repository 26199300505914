import styled from "styled-components";
import { Image, Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import './main-header.css'

import flexioTextLogo from "../../assets/images/webp/flexio_text_logo.webp";

function handleClick(event) {
  event.preventDefault(); // evita el comportamiento predeterminado del enlace
  const id = event.target.getAttribute('href'); // obtiene el id del enlace
  const element = document.querySelector(id); // obtiene el elemento por su id
  element.scrollIntoView({ behavior: 'smooth' }); // desplaza la vista al elemento suavemente
  /* window.history.pushState({}, '', '/mi-nueva-url'); // actualiza la URL sin cargar una nueva página */
}

const SubmenuComponent = () => {
  return (

        <ul className="submenuComponent">
            <li><a href="#command-center" className="menuItem" onClick={ handleClick }>Command Center</a></li>
            <li>|</li>
            <li><a href="#benefits-features" className="menuItem" onClick={ handleClick }>Main Benefits and Features</a></li>
            <li>|</li>
            <li><a href="#comparison-table" className="menuItem" onClick={ handleClick }>Comparison</a></li>
            <li>|</li>
            <li><a href="#hybrid-solution" className="menuItem" onClick={ handleClick }>Hybrid Solution</a></li>
            <li>|</li>
            <li><a href="#driver-app-info" className="menuItem" onClick={ handleClick }>Driver App</a></li>
            <li>|</li>
            <li><a href="#pricing-info" className="menuItem" onClick={ handleClick }>Pricing</a></li>
        </ul>

  );
}

const MainHeaderContainer = ({
  children,
  Title,
  mainText,
  headerImg,
  showLogo,
  mainTextClass,
  logoImgSize = 200,
  ...props
}) => {
  const location = useLocation();
  return (
    <FlexMainContainer {...props} fluid>
      <Row>
        <FlexMainTextCol md={5}>
          <div style={{ maxWidth: "500px", margin: "auto" }}>
            {showLogo && (
              <div className="pb-5">
                <Image src={flexioTextLogo} fluid width={logoImgSize} height="auto" alt="Flexio" />
              </div>
            )}
            <h1>{Title ? <Title /> : "Lorem Ipsum"}</h1>
            <p className={mainTextClass}>
              {mainText ||
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
              Phasellus ac ultricies turpis, id rhoncus lorem. 
              Sed semper velit ac tristique lacinia. Mauris pretium a 
              neque quis vestibulum.`}
            </p>
            <div>{children}</div>
          </div>
        </FlexMainTextCol>
        <Col md={7} className="d-flex justify-content-end p-0">
          <div>
            {headerImg ? (
              <Image src={headerImg} width="auto" height="auto" fluid alt={Title()} />
            ) : (
              <div
                style={{
                  backgroundColor: "#dfdfdf",
                  minHeight: "600px",
                  width: "90%",
                  margin: "auto"
                }}
              ></div>
            )}
          </div>
        </Col>
        {location.pathname === "/software" ? <SubmenuComponent /> : '' }
      </Row>
    </FlexMainContainer>
  );
};

const FlexMainContainer = styled(Container)`
  ${'' /* height: 100vh; */}

  @media (max-width: 1200px) {
    height: 80vh;
  }

  @media (max-width: 1024px) {
    margin-top: 3em;
    margin-bottom: 6em;
    flex-flow: wrap;
    height: auto;
  }
`;

const FlexMainTextCol = styled(Col)`
  display: flex;
  align-items: center;
  padding: 2em 2em 2em 10em;

  & p {
    font-size: 1.2em;
  }

  @media (max-width: 1024px) {
    padding: 2em;
  }
`;

export default MainHeaderContainer;
