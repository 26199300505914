import { Container, Image, Navbar, Nav } from "react-bootstrap";
import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom";
import flexioSmallLogo from "../../assets/images/webp/flexio_small_logo.webp";
import styled from "styled-components";

const NavigationBar = () => {
  const location = useLocation();
  return (
    <>
      <NavbarWrapper expand="lg">
        <Container className="justify-content-between" fluid>
          <Link to="/" className="navbar-brand pl-2">
            <Image src={flexioSmallLogo} width={60} height="auto" alt="Flexio logo"/>
          </Link>
          <Nav className="nav-menu">
            <Nav.Item className="text-center">
              <div>
                <CustomLink to="software">SOFTWARE</CustomLink>
              </div>
            </Nav.Item>
            <Nav.Item className="text-center">
              <div>
                <CustomLink to="delivery">DELIVERY</CustomLink>
              </div>
              {/* <small className="text-danger mt-1 d-block">
                Under construction
              </small> */}
            </Nav.Item>
            <Nav.Item className="text-center">
              <div>
                <CustomLink to="solutions">SOLUTIONS</CustomLink>
              </div>
            </Nav.Item>
             <Nav.Item className="text-center">
              <div>
                <CustomLink to="industries">INDUSTRIES</CustomLink>
              </div>
              {/* {location.pathname === "/industries" ? <small className="text-danger mt-1 d-block">
                Under construction
              </small> : '' } */}
            </Nav.Item>
            <Nav.Item className="text-center">
              <CustomLink to="become-driver">BECOME A DRIVER</CustomLink>
            </Nav.Item>
            {/* <Nav.Item className="text-center">
              <div>
              <a style={{ color: "#000", textDecoration: "none" }} href="https://goflexio.gitbook.io/flexio-routing-engine/" target="_blank" rel="noreferrer"
                onMouseEnter={e => e.target.style.color = "#0a58ca"}
                onMouseLeave={e => e.target.style.color = "#000"}
              >
                DEVELOPERS
              </a>
              </div>
            </Nav.Item> */}
          </Nav>
          <AuthNav className="nav-menu auth-menu fw-bold pr-2">
          <Nav.Item>
              <StyledLinkDemo
                to=""
                onClick={(e) => {
                   e.preventDefault();
                   window.open("https://calendly.com/adrian-medina/45min");
                 }}
              >
                REQUEST A DEMO
              </StyledLinkDemo>
            </Nav.Item>
            <Nav.Item>
              <StyledLink
                to=""
                // onClick={(e) => {
                //   e.preventDefault();
                //   window.open("http://myaccount.flexiopr.com/");
                // }}
                /*onClick={(e) => {
                  e.preventDefault();
                  window.FreshworksWidget("open");
                }}*/
                onClick={(e) => {
                   e.preventDefault();
                   window.open("https://app.goflexio.com/");
                 }}
              >
                LOGIN
              </StyledLink>
            </Nav.Item>
            <Nav.Item>
              <StyledLink
                to=""
                // onClick={(e) => {
                //   e.preventDefault();
                //   window.open("http://myaccount.flexiopr.com/");
                // }}
                /*onClick={(e) => {
                  e.preventDefault();
                  window.FreshworksWidget("open");
                }}*/
                onClick={(e) => {
                   e.preventDefault();
                   window.open("https://app.goflexio.com/register");
                 }}
              >
                REGISTER
              </StyledLink>
            </Nav.Item>
          </AuthNav>
        </Container>
      </NavbarWrapper>
    </>
  );
};

const StyledLink = styled(Link)`
  color: #000;
  margin: 1em;
  padding: 0.5em 1em;
  font-size: 1.1em;
`;
const StyledLinkDemo = styled(Link)`
  color: #fff !important;
  background-color: #6078ea;
  margin: 1em;
  padding: 0.5em 1em;
  font-size: 1.1em;
`;

const CustomLink = ({ to, children, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <StyledLink
      to={to}
      {...props}
      style={{
        borderBottom: match ? "solid 3px #6078EA" : 0,
        paddingBottom: match ? "0.3em" : 0
      }}
    >
      {children}
    </StyledLink>
  );
};

const NavbarWrapper = styled(Navbar)`
  background-color: #fff;
  font-size: 0.8em;

  @media (max-width: 1024px) {
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

const AuthNav = styled(Nav)`
  a {
    display: block;
    margin: 1em;
    color: #6078ea;
  }
`;

export default NavigationBar;