import { Link } from "react-router-dom";
import styled from "styled-components";

const CTAButton = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

const StyledButton = styled(Link)`
  display: block;
  background-color: ${(props) => props.bgColor || "#6078ea"};
  color: #ffffff;
  padding: 0.6em;
  text-align: center;
  max-width: ${(props) => props.size || "300px"};
  font-weight:bold;
  font-size:1.2em;

  &:hover {
    color: #ffffff;
  }
`;

export default CTAButton;
